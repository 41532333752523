<template>
  <div class="order-table">
    <ag-grid-vue
      style="width: 100%; height: 400px;"
      class="ag-theme-alpine"
      :gridOptions="gridOptions"
      @grid-ready="onGridReady"
      :columnDefs="columnDefs"
      :rowData="rowData"
      :rowSelection="rowSelection"
      :setQuickFilter="updateSearchQuery"
      suppressDragLeaveHidesColumns
      suppressMovableColumns
      @sortChanged="onSortChange"
      :singleClickEdit="true"
      :stopEditingWhenCellsLoseFocus="true"
      @bodyScroll="hideEdit"
      :overlayLoadingTemplate="overlayLoadingTemplate"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import moment from "moment";
import { statusOptionsMixin } from "@/mixin/BorrowReturn/statusOptionsMixin";
import { mapGetters } from "vuex";

//  Table Components
import assetImg from "../components/assetImg.vue";
import DetailIcon from "./CellFramework/AssetDetailIcon.vue";
import BorrowReturnStatusCardCell from "./CellFramework/BorrowReturnStatusCardCell.vue";
import BorrowerDetail from "./CellFramework/BorrowerDetail.vue";
import StatusDropdown from "./CellFramework/BorrowStatusDropdown.vue";
import StatusOption from "./CellFramework/BorrowStatusOption.vue";
import NoteIcon from "./CellFramework/AssetNoteIcon.vue";
import EditIcon from "./CellFramework/EditBorrowIcon.vue";
import { BorrowReturnStatuses } from "../../../constants/borrow-return/BorrowReturnStatuses";

export default {
  emits: [
    "interface",
    "selected-count",
    "change-status",
    "detail-click",
    "note-click",
    "edit-click",
  ],
  components: {
    AgGridVue,
    assetImg,
    DetailIcon,
    BorrowerDetail,
    StatusDropdown,
    BorrowReturnStatusCardCell,
    StatusOption,
    NoteIcon,
    EditIcon,
  },
  mixins: [statusOptionsMixin],
  data() {
    return {
      overlayLoadingTemplate:
        '<span class="ag-overlay-loading-center">Loading</span>',
      columnDefs: null,
      gridApi: null,
      gridColumnApi: null,
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
      selectedRowData: new Map(),
    };
  },
  props: {
    canChangeStatus: { type: Boolean, required: false, default: false },
    canEdit: { type: Boolean, required: false, default: false },
    rowSelection: { required: false, default: "multiple" },
    page: { type: Number },
    perPage: {
      type: Number,
      required: false,
      default: 100,
    },
    assetData: { default: () => [] },
    searchText: {
      type: String,
    },
    otherColumn: { default: () => [] },
  },
  beforeMount() {
    this.columnDefs = [
      {
        headerName: this.$t("count.order"),
        field: "index",
        headerCheckboxSelection:
          this.rowSelection === "multiple" ? true : false,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: this.rowSelection !== "none" ? true : false,
        maxWidth: 110,
        suppressSizeToFit: true,
        cellClass: "text-center",
      },
      {
        headerName: this.$t("borrow.asset_image"),
        field: "asset_image",
        sortable: true,
        suppressSizeToFit: true,
        cellRenderer: "assetImg",
      },
      {
        headerName: this.$t("borrow.borrow_id"),
        field: "borrow_id",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("home.fixedas_num"),
        field: "id_fixed_asset",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("home.as_name"),
        field: "name",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("borrow.detail"),
        field: "detail",
        sortable: true,
        width: 100,
        cellRenderer: "DetailIcon",
        cellClass: "text-center",
        cellRendererParams: {
          onDetailIconClick: this.onDetailIconClick.bind(this),
        },
      },
      {
        headerName: this.$t("borrow.ref1"),
        field: "ref1",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("borrow.ref2"),
        field: "ref2",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("borrow.borrower"),
        field: "borrower",
        sortable: true,
        suppressSizeToFit: true,
        cellRenderer: "BorrowerDetail",
      },
      {
        headerName: this.$t("borrow.borrow_date"),
        field: "borrow_date",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("borrow.borrow_due_date"),
        field: "borrow_due_date",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("borrow.return_date"),
        field: "return_date",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("borrow.verifier"),
        field: "verifier",
        sortable: true,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("borrow.status"),
        field: "status",
        suppressSizeToFit: true,
        minWidth: 200,
        width: 220,
        cellRenderer: this.$props.canChangeStatus
          ? "StatusDropdown"
          : "BorrowReturnStatusCardCell",

        ...(this.$props.canChangeStatus
          ? {
              cellClass: "overflow-visible",
              cellRendererParams: {
                onStatusChange: this.onStatusChange.bind(this),
              },
              // only allow editing if the status has options
              editable: (params) =>
                params.data.status.options !== null &&
                this.$props.canChangeStatus,

              // uses a custom Cell Editor
              cellEditor: "StatusOption",
              // provides params to the Cell Editor
              cellEditorParams: {
                onStatusChange: this.onStatusChange.bind(this),
              },
              // show this editor in a popup
              cellEditorPopup: true,
              // position the popup under the cell
              cellEditorPopupPosition: "under",
            }
          : {}),
      },
      {
        headerName: this.$t("borrow.note"),
        field: "borrow_note",
        sortable: true,
        suppressSizeToFit: true,
        maxWidth: 100,
        cellRenderer: "NoteIcon",
        cellClass: "text-center",
        cellRendererParams: {
          onNoteIconClick: this.onNoteIconClick.bind(this),
        },
      },
      {
        headerName: this.$t("borrow.edit"),
        field: "edit",
        sortable: true,
        suppressSizeToFit: true,
        hide: !this.$props.canEdit,
        maxWidth: 100,
        cellRendererSelector: (params) => {
          if (
            [
              BorrowReturnStatuses.TO_PREPARE,
              BorrowReturnStatuses.PREPARED,
              BorrowReturnStatuses.TO_SEND,
              BorrowReturnStatuses.NOT_AVAILABLE_PREPARE,
              BorrowReturnStatuses.NOT_AVAILABLE_SEND,
            ].includes(params.data.status.name)
          ) {
            return {
              component: "EditIcon",
              params: {
                onEditIconClick: this.onEditIconClick.bind(this),
                show: true,
              },
            };
          }

          return { component: "EditIcon", params: { show: false } };
        },
        cellClass: "text-center",
      },
    ];
  },
  computed: {
    ...mapGetters({
      permissionStatus: "permissionStatus",
    }),

    rowData() {
      return (
        this.assetData?.map((asset, index) => {
          return {
            index: (this.page - 1) * this.perPage + index + 1,
            borrow_asset_id: asset.id,
            borrow_id: asset.borrow_return_request.fixed_request_id,
            asset_image: asset.asset.image_url?.url || "",
            id_fixed_asset: asset.asset.id_fixed_asset,
            name: asset.asset.name,
            ref1: asset?.ref_no_1 || "-",
            ref2: asset?.ref_no_2 || "-",
            detail: asset,
            borrow_note: asset,
            edit: asset,
            borrow_date: moment(asset.start_datetime).format(
              "DD/MM/YYYY HH:mm"
            ),
            borrow_due_date: moment(asset.end_datetime).format(
              "DD/MM/YYYY HH:mm"
            ),
            return_date: asset.return_datetime
              ? moment(asset.return_datetime).format("DD/MM/YYYY HH:mm")
              : "-",
            verifier: asset?.approve_user?.name || "-",
            status: {
              name: asset.status,
              options:
                this.canChangeStatus &&
                this.getAllowanceStatusOptions(asset.status),
            },
            borrower: {
              name: asset.borrow_return_request.user.name,
              email: asset.borrow_return_request.user.email,
              phone: asset.borrow_return_request.user.phone,
            },
          };
        }) || null
      );
    },
  },
  watch: {
    searchText() {
      this.updateSearchQuery(this.searchText);
    },
  },
  methods: {
    hideEdit() {
      this.gridApi.stopEditing();
    },
    onSortChange() {
      const colState = this.gridColumnApi.getColumnState();
      const sortState = colState
        .filter(function(s) {
          return s.sort != null;
        })
        .map(function(s) {
          return {
            colId: s.colId,
            sort: s.sort,
            sortIndex: s.sortIndex,
          };
        });
      console.log(sortState);
    },

    onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
    },

    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
      this.gridApi.refreshCells();
    },
    onStatusChange({ oldStatus, newStatus, borrow_asset_id }) {
      this.$emit("change-status", { oldStatus, newStatus, borrow_asset_id });
    },
    onDetailIconClick(assetDetail) {
      this.$emit("detail-click", assetDetail);
    },
    onNoteIconClick(assetDetail) {
      this.$emit("note-click", assetDetail);
    },
    onEditIconClick(value) {
      this.$emit("edit-click", value);
    },
    emitInterface() {
      this.$emit("interface", {
        refreshCells: (options) => {
          this.gridApi.refreshCells(options);
        },
        showLoading: () => this.gridApi.showLoadingOverlay(),
        hideLoading: () => this.gridApi.hideOverlay(),
      });
    },
  },
  created() {
    if (this.$route.name === "select-borrow") {
      this.selectedRowData = new Map(
        this.$store.getters["borrow/getBorrowAsset"]
      );
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.emitInterface();
  },
};
</script>

<style scoped lang="scss">
.order-table {
  ::v-deep .ag-header-row-column {
    background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
    color: white;
  }
  ::v-deep .ag-row {
    z-index: 0;
    &.ag-row-focus {
      z-index: 1;
    }
  }

  ::v-deep .ag-popup-editor {
    background-color: #fff;
    padding: 0.5rem;
    border: 1px solid #d2d2d2;
    border-radius: 10px;
  }
}
</style>
